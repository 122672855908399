import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import './style.scss'
import InputMask from 'react-input-mask';

export default function PromotionsFilter({ showStatus }) {

    const [checkboxesStatus, setCheckboxesStatus] = useState([
        { id: 1, label: 'All', checked: false },
        { id: 2, label: 'Rejected', checked: false },
    ]);

    const [isStatusShow, setIsStatusShow] = useState(false)

    const handleCheckboxStatusChange = (id) => {
        setCheckboxesStatus(
            checkboxesStatus.map((checkbox) =>
                checkbox.id === id
                    ? { ...checkbox, checked: true }
                    : { ...checkbox, checked: false }
            )
        );
    };

    const statusHide = () => {
        setIsStatusShow(false);
    };

    const statusShow = () => {
        setIsStatusShow(true);
    };


    const [checkboxesModerators, setCheckboxesModerators] = useState([
        { id: 1, label: 'All', checked: false },
        { id: 2, label: 'superindus@testmail.com', checked: false },
        { id: 3, label: 'moderator_1@testmail.com', checked: false },
        { id: 4, label: 'moderator_22@testmail.com', checked: false },
        { id: 5, label: 'moderator_ridus@testmail.com', checked: false },
    ]);

    const [isModeratorsShow, setIsModeratorsShow] = useState(false)

    const handleCheckboxModeratorsChange = (id) => {
        setCheckboxesModerators(
            checkboxesModerators.map((checkbox) =>
                checkbox.id === id
                    ? { ...checkbox, checked: true }
                    : { ...checkbox, checked: false }
            )
        );
    };

    const moderatorsHide = () => {
        setIsModeratorsShow(false);
    };

    const moderatorsShow = () => {
        setIsModeratorsShow(true);
    };


    const [startDate, setStartDate] = useState(new Date("2023-01-1"));
    const [endDate, setEndDate] = useState(new Date());
    const [endDateRequest, setEndDateRequest] = useState(new Date());

    const padZero = (num) => (num < 10 ? `0${num}` : num);

    const monthStart = padZero(startDate.getMonth() + 1);
    const dayStart = padZero(startDate.getDate());
    const yearStart = startDate.getFullYear();
    const yearStartShort = padZero(startDate.getFullYear() % 100);

    const startDateFormattedDate = `${dayStart}.${monthStart}.${yearStart}`;


    const monthEnd = padZero(endDateRequest.getMonth() + 1);
    const dayEnd = padZero(endDateRequest.getDate());
    const yearEnd = endDateRequest.getFullYear();
    const yearEndShort = padZero(endDateRequest.getFullYear() % 100);
    const endDateFormattedDate = `${dayEnd}.${monthEnd}.${yearEnd}`

    const [dateChange, setDateChange] = useState(false);
    useEffect(() => {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        setStartDate(firstDayOfMonth);
    }, []);

    const [isBlockVisible, setIsBlockVisible] = useState(false);

    const dateHide = () => {
        setIsBlockVisible(false);
    };

    const dateShow = () => {
        setIsBlockVisible(true);
    };

    const dataPickerLogoutRef = useRef(null);

    const handleChange = ([newStartDate, newEndDate]) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        if (newEndDate) {
            setEndDateRequest(newEndDate)
            setDateChange(true)
        }
    };

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');


    const handleInputChange = (e, setTime) => {
        setTime(e.target.value);
    };


    const [isTimeShow, setIsTimeShow] = useState(false)

    const timeHide = () => {
        setIsTimeShow(false);
    };

    const timeShow = () => {
        setIsTimeShow(true);
    };


    const handleClear = () => {
        setStartTime('')
        setEndTime('')
        setDateChange(false)
        setCheckboxesModerators(
            checkboxesModerators.map((checkbox) => ({
                ...checkbox,
                checked: false
            }))
        );
        setCheckboxesStatus(
            checkboxesStatus.map((checkbox) =>
                ({ ...checkbox, checked: false })
            )
        );
    }
    return (
        <div className="promotions-filter">
            {showStatus && (
                <div className="promotions-filter-select">
                    <div className="promotions-filter-select-wrap" onClick={statusShow}>
                        <span>Ad status</span>
                        {checkboxesStatus.find((checkbox) => checkbox.checked) && (
                            <strong>
                                {checkboxesStatus.find((checkbox) => checkbox.checked)?.label}
                            </strong>
                        )}
                        <div className={`promotions-filter-select__arrow ${isStatusShow && 'active'}`}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 6L8 11L3 6" stroke="#ABABAB" stroke-width="1.9" stroke-linecap="round" />
                            </svg>

                        </div>
                    </div>

                    {isStatusShow && (
                        <div className="promotions-filter-dropdown">
                            <div className="promotions-filter-dropdown__title">
                                Ad status
                            </div>

                            {checkboxesStatus.map((checkbox) => (
                                <div
                                    key={checkbox.id}
                                    className={`promotions-filter-checkbox ${checkbox.checked ? 'active' : ''}`}
                                    onClick={() => handleCheckboxStatusChange(checkbox.id)}
                                >
                                    <div className="promotions-filter-checkbox__icon">
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.66016 7.04723L1.29726 4.68433C1.12292 4.50999 0.840253 4.50999 0.665912 4.68433C0.491571 4.85867 0.491571 5.14133 0.665912 5.31567L3.34448 7.99424C3.51883 8.16859 3.80149 8.16859 3.97583 7.99424L9.33297 2.6371C9.50731 2.46276 9.50731 2.1801 9.33297 2.00576C9.15863 1.83141 8.87597 1.83141 8.70163 2.00576L3.66016 7.04723Z" fill="white" stroke="white" stroke-width="0.78125" />
                                        </svg>

                                    </div>
                                    <span>{checkbox.label}</span>
                                </div>
                            ))}


                            <div className="promotions-filter-dropdown__btn" onClick={statusHide}>Select</div>
                        </div>
                    )}

                </div>
            )}

            <div className="promotions-filter-select">
                <div className="promotions-filter-select-wrap" onClick={moderatorsShow}>
                    <span>Moder</span>
                    {checkboxesModerators.find((checkbox) => checkbox.checked) && (
                        <strong>
                            {checkboxesModerators.find((checkbox) => checkbox.checked)?.label}
                        </strong>
                    )}
                    <div className={`promotions-filter-select__arrow ${isModeratorsShow && 'active'}`}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 6L8 11L3 6" stroke="#ABABAB" stroke-width="1.9" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>

                {isModeratorsShow && (
                    <div className="promotions-filter-dropdown">
                        <div className="promotions-filter-dropdown__title">
                            Moderators
                        </div>


                        {checkboxesModerators.map((checkbox) => (
                            <div
                                key={checkbox.id}
                                className={`promotions-filter-checkbox ${checkbox.checked ? 'active' : ''}`}
                                onClick={() => handleCheckboxModeratorsChange(checkbox.id)}
                            >
                                <div className="promotions-filter-checkbox__icon">
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.66016 7.04723L1.29726 4.68433C1.12292 4.50999 0.840253 4.50999 0.665912 4.68433C0.491571 4.85867 0.491571 5.14133 0.665912 5.31567L3.34448 7.99424C3.51883 8.16859 3.80149 8.16859 3.97583 7.99424L9.33297 2.6371C9.50731 2.46276 9.50731 2.1801 9.33297 2.00576C9.15863 1.83141 8.87597 1.83141 8.70163 2.00576L3.66016 7.04723Z" fill="white" stroke="white" stroke-width="0.78125" />
                                    </svg>

                                </div>
                                <span>{checkbox.label}</span>
                            </div>
                        ))}


                        <div className="promotions-filter-dropdown__btn" onClick={moderatorsHide}>Select</div>
                    </div>
                )}

            </div>
            <div className="promotions-filter-select" >
                <div className="promotions-filter-select-wrap" onClick={dateShow}>
                    <span>Date</span>
                    {dateChange && (
                        <strong>      {startDateFormattedDate} - {endDateFormattedDate}
                        </strong>
                    )}

                    <div className="promotions-filter-select__icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_9068_13800)">
                                <path d="M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M2 6.00016H14M12.6667 2.66683H3.33333C2.59667 2.66683 2 3.2635 2 4.00016V12.6668C2 13.4035 2.59667 14.0002 3.33333 14.0002H12.6667C13.4033 14.0002 14 13.4035 14 12.6668V4.00016C14 3.2635 13.4033 2.66683 12.6667 2.66683Z" stroke="#ABABAB" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_9068_13800">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>

                {isBlockVisible && (
                    <div className="promotions-filter-dropdown">
                        <div className="analytics-top-date-block" ref={dataPickerLogoutRef} >
                            <DatePicker
                                selected={startDate}
                                onChange={handleChange}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={new Date()}
                                inline
                            />
                        </div>
                        <div className="promotions-filter-dropdown__btn" onClick={dateHide}>Select</div>
                    </div>
                )}

            </div>
            <div className="promotions-filter-select">
                <div className="promotions-filter-select-wrap" onClick={timeShow}>
                    <span>Time</span>
                    {(startTime && endTime) && (
                        <strong>{startTime}-{endTime}
                        </strong>
                    )}

                    <div className={`promotions-filter-select__arrow ${isTimeShow && 'active'}`}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 6L8 11L3 6" stroke="#ABABAB" stroke-width="1.9" stroke-linecap="round" />
                        </svg>
                    </div>
                </div>
                {
                    isTimeShow && (
                        <div className="promotions-filter-dropdown">
                            <div className="promotions-filter-dropdown__title" style={{ textAlign: "center" }}>
                                Time Diapason
                            </div>
                            <div className="promotions-filter-dropdown-time">
                                <InputMask mask="99:99" value={startTime}
                                    onChange={(e) => handleInputChange(e, setStartTime)}
                                    placeholder='__:__'
                                >
                                </InputMask>

                                <span>—</span>
                                <InputMask placeholder='__:__' mask="99:99" value={endTime} onChange={(e) => handleInputChange(e, setEndTime)}>
                                </InputMask>
                            </div>
                            <div className="promotions-filter-dropdown__btn" onClick={timeHide}>Select</div>
                        </div>
                    )
                }
            </div>

            {(checkboxesModerators.find((checkbox) => checkbox.checked) || checkboxesStatus.find((checkbox) => checkbox.checked) || dateChange || (startTime && endTime)) && (
                <div className="promotions-filter-clear" onClick={handleClear}>
                    Clear
                </div>
            )}

        </div>
    )
}