import React from 'react'
import './style.scss'

function ProductImages({ data }) {
    return (
        <div className="products-images">
            <div className="products-images__title">
                Images
            </div>
            <div className="products-images-wrapper">
                {data?.photos?.map((photo) => (
                    <div className="products-images__img" key={photo.id}>
                        <img src={photo.url} alt="" />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProductImages